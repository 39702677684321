import React from "react";
import "./style.css";

const ServiceDetails = () => {
  return (
    <div className="service-details">
      <h2 className="title">Daba Express</h2>
      <ul className="details-list">
        <li >Lavage Complet</li>
        <li className="highlight">Service À Domicile</li>
        <li>Environ 50min</li>
        <li className="highlight">Moins de 10 L</li>
      </ul>
      <a
        href="https://wa.me/212637487851"
        className="whatsapp-button"
        target="_blank"
        rel="noopener noreferrer"
      >
        Réservez ici
      </a>
    </div>
  );
};

export default ServiceDetails;
