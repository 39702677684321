import React, { useState } from "react";
import "./nav.css";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [t, i18n] = useTranslation("global");

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="navbar">
      {/* Left Section */}
      <div className="navbar-left">
        {/* Hamburger Menu for Mobile */}
        <button className="menu-button" onClick={toggleMenu} aria-label="Toggle menu">
  <i className="fas fa-bars" 
  style={{
    color:"#0072ce"
  }}
  ></i>
</button>



        {/* Desktop Menu */}
        <div className="desktop-menu">
          <ul>
            <li><a href="/">{t("header.home")}</a></li>
            <li><a href="/contact">{t("header.contact")}</a></li>
            <li><a href="/newsmain">{t("header.blogs")}</a></li>
            <li><a href="/about">{t("header.about")}</a></li>
          </ul>
        </div>

      </div>

      {/* Center Section */}
      <div className="navbar-center">
        <div className="logo">daba</div>
        <p className="slogan">On vient, on lave, on brille !</p>
      </div>

      {/* Right Section */}
      <div className="navbar-right">
        <a
          href="https://wa.me/212637487851"
          target="_blank"
          rel="noopener noreferrer"
          className="whatsapp-button"
        >
          <i className="fab fa-whatsapp whatsapp-icon"></i>
          <span className="desktop-contact">Contact Us</span>
        </a>
      </div>
      
        {/* Mobile Dropdown Menu */}
        {menuOpen && (
    <div className={`nav-menu-dropdown ${menuOpen ? "open" : ""}`}>
      <ul>
        <li><a href="/" onClick={toggleMenu}>{t("header.home")}</a></li>
        <li><a href="/contact" onClick={toggleMenu}>{t("header.contact")}</a></li>
        <li><a href="/blog" onClick={toggleMenu}>{t("header.blogs")}</a></li>
        <li><a href="/about" onClick={toggleMenu}>{t("header.about")}</a></li>
      </ul>
    </div>
  )}
    </nav>
    
  );
};

export default Navbar;
