import React from 'react'
import { Link } from 'react-router-dom'
import "./banner.css" ;

function Banner() {
  return (
    <div className="banner">
     
      <div className="features">
        <table>
          <tbody>
            <tr>
              <td rowSpan={4}>
              <img
                    src="assets/images/image.png" // Ensure the path matches where you have stored your image
                    alt="Car Image"
                    className="car-image"
                  />
              </td>
            </tr>

           
            <tr>
                <td class="icon-cell">
                  <i className="fas fa-tag"></i>
                  <br />
                  Pas cher 100 DH
                </td>
              </tr>

              <tr>
                <td class="icon-cell">
                  <i className="fas fa-bolt"></i>
                  <br />
                  Zéro attente
                </td>
              </tr>

              <tr>
                <td class="icon-cell">
                  <i className="fas fa-shield-alt"></i>
                  <br />
                  Lavage sécurisé
                </td>
              </tr>

            <tr>
            <td
                  style={{
                    textAlign: "center", // Horizontal alignment
                    verticalAlign: "middle", // Vertical alignment
                  }}
                >
                  <a
                    href="https://wa.me/212637487851"
                    className="whatsapp-button"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      display: "inline-block",
                      padding: "10px 20px",
                      border: "2px solid #007BFF", // Blue border
                      color: "#007BFF", // Blue text color
                      textDecoration: "none",
                      borderRadius: "25px", // Fully rounded corners
                      fontFamily: "Arial, sans-serif",
                      fontSize: "16px",
                      fontWeight: "bold",
                      backgroundColor: "transparent", // No background
                      transition: "all 0.3s ease", // Smooth hover effect
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = "#007BFF"; // Blue background on hover
                      e.target.style.color = "#FFF"; // White text on hover
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "transparent"; // Revert to transparent
                      e.target.style.color = "#007BFF"; // Revert to blue text
                    }}
                  >
                    Réservez ici
                  </a>
                </td>
                <td
                  style={{
                    textAlign: "center",
                    verticalAlign: "middle", // Ensures alignment matches with the button
                  }}
                >
                  📍 Partout à Marrakech
                </td>
              </tr>

          </tbody>
        </table>
      
      </div>
    </div>
  )
}

export default Banner